<template>
  <section id="home" v-if="landingCollection && personalCollection">
    <!-- Main content -->
    <div class="cover-content d-flex flex-column align-center justify-center">
      <template v-if="personalCollection.isAllowed">
      <h2 class="mb-3 display-2">
        {{personalCollection.firstName}}
        <span class="primary--text">{{personalCollection.lastName}}</span>
      </h2>
      <h3 class="my-3 display-1">{{personalCollection.currentJobTitle}}</h3>
      </template>
      <div class="d-flex mt-4">
        <v-btn
          outlined
          router :to="{name: `Portfolio`}"
          class="mt-3 pa-5 btn-outline"
        >
          View My Portfolio
        </v-btn>
      </div>
    </div>
    <!-- BG photo -->
    <v-img
      class="cover-img"
      :src="landingCollection.coverPicture.url"
    ></v-img>
  </section>
</template>

<script>
import firebaseGetCollection from '@/mixins/firebase8/collection/get';

export default {
  name: 'Home',

  data: () => ({
    landingCollectionRaw: null,
    personalCollectionRaw: null,
  }),

  computed: {
    landingCollection() {
      return (this.landingCollectionRaw && this.landingCollectionRaw.results)
        ? this.landingCollectionRaw.results[0]
        : null;
    },

    personalCollection() {
      return (this.personalCollectionRaw && this.personalCollectionRaw.results)
        ? this.personalCollectionRaw.results[0]
        : null;
    },
  },

  async created() {
    await this.subToCollection();
  },

  destroyed() {
    this.unSubToCollection();
  },

  methods: {
    async subToCollection() {
      this.landingCollectionRaw = await firebaseGetCollection('landing');

      this.personalCollectionRaw = await firebaseGetCollection('personal');
    },

    unSubToCollection() {
      this.landingCollectionRaw = null;
      this.personalCollectionRaw = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.cover-content {
  z-index: 1;
  position: absolute;
  top: 0; bottom: 0;
  left: 0; right: 0;
  height: calc(100vh - 60px);
}

.cover-img {
  top: 0; bottom: 0;
  left: 0; right: 0;
  height: calc(100vh - 60px);
}
</style>
